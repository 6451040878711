import React from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { Icon } from "../../Icon";
import Button from "../../Button";
import "./ProductsTabsArrows.css";
import classNames from "../../../functions/classNames";
import usePagination from "../../../common/hooks/usePagination";
import { isSSR } from "../../NoSSR";

/**
 * Arrows displaying in `ProductsTabs`
 * @param {Object} $
 * @param {Number} $.current - current tab index
 * @param {Number} $.amount - amount of tabs
 * @param {Number} $.possibleAmount - manually given amount of the tabs
 * @param {Function} $.changeTab - changes tab
 */
export default function ProductsTabsArrows({ current, amount, possibleAmount, changeTab }) {
  const isMobile = useMediaQuery("(max-width: 767px)") && isSSR;

  // Calculate the maximum number of reviews to show per page based on device type
  const reviewsPerPage = isMobile ? 3 : 6;

  // Calculate the total number of pages based on the total number of reviews and reviews per page
  const totalPages = Math.ceil(amount / reviewsPerPage);

  const { items } = usePagination({
    count: possibleAmount, // Use possibleAmount as the count of items for pagination
    onChange: (event, page) => changeTab(page),
  });

  if (items.length === 1) return null; // If there's only one page, no need to show the pagination

  return (
    <div className="flex-center ProductsTabsArrows">
      {items.map(({ page, type, selected, ...item }) => {
        if (type === "previous") {
          return (
            <Button
              key={type}
              className="ProductsTabsArrows__arrow"
              disabled={current === 0}
              icon={<Icon name="chevron/left" width="18" height="18" />}
              theme="simple"
              {...item}
            />
          );
        } else if (type === "start-ellipsis" || type === "end-ellipsis") {
          return (
            <div key={type} className="ProductsTabsArrows__dots">
              ...
            </div>
          );
        } else if (type === "page") {
          return (
            <div
              key={page}
              className={classNames(
                "ProductsTabsArrows__page",
                selected && "ProductsTabsArrows__page--current",
              )}
              {...item}
              {...(selected ? { onClick: () => {} } : {})}
            >
              {page}
            </div>
          );
        } else if (type === "next") {
          return (
            <Button
              key={type}
              className="ProductsTabsArrows__arrow"
              disabled={current + 1 === totalPages} // Use totalPages instead of amount
              icon={<Icon name="chevron/right" width="18" height="18" />}
              theme="simple"
              {...item}
            />
          );
        }
        return null;
      })}
    </div>
  );
}

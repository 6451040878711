/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from "react";
import uuid from "react-uuid";
import { Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import classNames from "../../functions/classNames";
import Spinner from "../Spinner";
import ReviewTabs from "../ProductsTabs";
import ReviewGroup from "./ReviewGroup";
import ReviewPopupAdapter from "./ReviewPopupAdapter";
import fetchReviews from "./fetchReviews";
import Review from "./Review";
import "./Reviews.css";

/**
 * Line heights of the desktop and mobile texts in `TextExpand` contained in `Review`
 * For right hiding text in `TextExpand`
 */
const MOBILE_LINE_HEIGHT = 21.5;
const DESKTOP_LINE_HEIGHT = 24.5;

/**
 * Calculates amount of pages
 * Takes into note banners posted on the 3rd placed on each slide
 * @param {Number} amount - amount of reviews
 * @param {Number} limit - amount of reviews per page
 */
function calculatePagesAmount(amount, limit) {
  let pagesAmount = 0;
  let reviewsAmount = amount;

  do {
    const prevPages = pagesAmount;
    pagesAmount += Math.floor(reviewsAmount / limit);
    reviewsAmount = pagesAmount - prevPages + (reviewsAmount % limit);
  } while (reviewsAmount > limit - 1);

  return reviewsAmount > 0 ? pagesAmount + 1 : pagesAmount;
}

/**
 * Shows reviews with pagination
 * @param {Object} $
 * @param {String|Undefined} $.type - `"list"` or `"grid"`
 * @param {Number} $.limit - max amount of reviews at the screen
 * @param {Number|Undefined} $.amount - total amount of reviews (including not fetched)
 * @param {Array[Object]} $.items - reviews
 * @param {Boolean} $.banner - add banner images to grid
 * @param {Boolean} $.loading - show loading
 * @param {Number} $.current - for controlled current page position
 * @param {Boolean} $.isMobile - mobile layout required
 * @param {Function} $.onRequest - calls when tab with loader reached
 * @param {Object} $.props - will be passed into `Review` as an addition
 */
export default function Reviews({
  isMobile,
  items,
  limit,
  type = "list",
  amount: reviewsAmount,
  banner,
  loading,
  current,
  lineHeight,
  convertReviewFn,
  onRequest = () => {},
  ...props
}) {
  /**
   * Array with arrays of reviews displaying on each page
   * @type {Array[Array[Object]]}
   */

  const groups = items.reduce(
    (acc, item) => {
      const last = acc.length - 1;
      function append(something) {
        if (acc[last].length < limit) {
          acc[last].push(something);
        } else {
          acc.push([something]);
        }
      }

      if (acc[last].length === 2 && banner) append("banner");
      append(item);

      return acc;
    },
    [[]],
  );

  let amount;
  if (banner) {
    amount = reviewsAmount ? calculatePagesAmount(reviewsAmount, limit) : 0;
  } else {
    amount = reviewsAmount ? Math.ceil(reviewsAmount / limit) : 0;
  }

  useEffect(() => {
    if ((isMobile && items.length > limit) || (!isMobile && items.length < limit)) {
      onRequest(current);
    }
  }, [isMobile]);

  /**
   * Checking if last tab fullfilled with reviews
   * If there are not enough reviews, it will be removed temporarely
   */
  // if (items.length < reviewsAmount && groups[groups.length - 1].length !== limit) groups.pop();
  // if (items.length < reviewsAmount) groups.push(["loader"]);

  /**
   * For calculating loader height
   * Takes height from last filled tab
   */
  const lastReviewGroup = useRef();
  const [lastGroupHeight, updateLastGroupHeight] = useState();

  return (
    <div className={classNames("Reviews", `Reviews--${type}`)}>
      <ReviewTabs
        arrows
        current={current}
        header={false}
        amount={amount || groups.length}
        onBeforeChange={() => {
          if (lastReviewGroup.current) {
            const { height } = lastReviewGroup.current.getBoundingClientRect();
            updateLastGroupHeight(height);
          }
        }}
        onChanged={index => {
          onRequest(index);
        }}
      >
        {groups.map((group, i) => (
          <div
            key={uuid()}
            className="Reviews__group"
            {...(i === groups.length - 1 ? { ref: lastReviewGroup } : {})}
          >
            <ReviewGroup type={loading ? "empty" : type}>
              {loading ? (
                <div
                  key={uuid()}
                  className="flex-center Reviews__loader"
                  style={{ height: `${lastGroupHeight}px` }}
                >
                  <Spinner theme="main">
                    <Trans>Loading reviews...</Trans>
                  </Spinner>
                </div>
              ) : (
                group.map(item => {
                  const review = convertReviewFn ? convertReviewFn(item) : item;
                  return (
                    <Review
                      key={uuid()}
                      {...review}
                      {...props}
                      lineHeight={
                        isMobile
                          ? lineHeight || MOBILE_LINE_HEIGHT
                          : lineHeight || DESKTOP_LINE_HEIGHT
                      }
                    />
                  );
                })
              )}
            </ReviewGroup>
          </div>
        ))}
      </ReviewTabs>
    </div>
  );
}

/**
 * Fully set up top level reviews block with title, text and banner
 * Interacts with `reviews` store
 * @param {Object} $
 * @param {String} $.className - additional CSS classes
 * @param {String} $.lang - current language
 * @param {String?} $.title - title
 * @param {Object} $.queryParams - additional params to use in requests
 * @param {Boolean} $.isMobile - mobile version required
 */
export function CustomerReviews({
  className,
  lang,
  title,
  queryParams,
  isMobile,
  perPage,
  withPopup = false,
  reviews: passedReviews,
  lineHeight,
  convertReviewFn = false,
  fetchFunction = fetchReviews,
}) {
  const {
    reviews: stateReviews = [],
    count,
    current,
    loading,
  } = useSelector(({ reviews: storeReviews }) => storeReviews);

  const reviews = passedReviews || stateReviews;

  const additionalProps = passedReviews ? {} : { loading, current, amount: count };

  const reviewsInGrid = isMobile ? 3 : 6;
  const dispatch = useDispatch();

  if (!reviews.length && !count) {
    return null;
  }

  return (
    <div className={classNames("CustomerReviews", className)}>
      <h2 className="CustomerReviews__title h1 m-32 m-64-t">{title}</h2>
      {withPopup ? (
        <ReviewPopupAdapter
          lang={lang}
          passedReviews={passedReviews}
          convertReviewFn={convertReviewFn}
          reviews={reviews}
          preview={reviews.slice(0, 6)}
          type="grid"
          popupTheme={isMobile ? "bottomSheet" : ""}
          onFirstOpen={fetchFunction({
            dispatch,
            reviewsNumber: perPage,
            replaceStore: true,
            lang,
            queryParams,
          })}
          onPaginate={fetchFunction({
            dispatch,
            reviewsNumber: perPage,
            replaceStore: false,
            lang,
            queryParams,
          })}
          {...additionalProps}
        />
      ) : (
        <Reviews
          showLink
          isMobile={isMobile}
          limit={reviewsInGrid}
          items={reviews}
          convertReviewFn={convertReviewFn}
          lang={lang}
          textLength={34}
          lineHeight={lineHeight}
          type="grid"
          banner={false}
          linesAmount={3}
          onRequest={fetchFunction({
            dispatch,
            reviewsNumber: reviewsInGrid,
            replaceStore: true,
            lang,
            queryParams,
          })}
          {...additionalProps}
        />
      )}
    </div>
  );
}

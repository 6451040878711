import React, { useState, useEffect, useRef } from "react";
import { Trans } from "@lingui/macro";
import classNames from "../../functions/classNames";
import "./TextExpand.css";

/**
 * Spoiler for the text
 * @param {String} className - additional CSS class name (will be added to root HTMLElement of the component)
 * @param {Number} limit - amount of words to show in shrinked mode
 * @param {Number} linesAmount - max amount of lines (used insted of `limit` with `lineHeight`)
 * @param {Number} lineHeight - review text line height in px
 * @param {React::Component} expanderContent - content of the "Expand" button
 * @param {React::Component} expanderContentOpen - content of the "Show less" button when open
 * @param {String} fullText - text to show after expanding
 * @param {String} shortText - text to show in shrinked mode (if not specified, `fillText` prop will be cut accordind to `limit`)
 * @param {String} theme - CSS theme modificator id
 * @param {Boolean} html - if `fullText` and `shortText` is HTML
 * @param {Boolean} $.allowOriginalFormat - shows original text with all extra spaces and newlines
 * @param {Function} $.onExpanded - user clicked "Expand" button
 */
export default ({
  className,
  html,
  limit,
  linesAmount,
  lineHeight,
  expanderContent,
  expanderContentOpen,
  fullText,
  shortText,
  theme,
  allowOriginalFormat,
  onExpanded = () => {},
  showButton = true,
}) => {
  let shortTextGenerated = "";
  if (linesAmount) {
    shortTextGenerated = fullText;
  } else if (!shortText && limit) {
    shortTextGenerated = fullText.length > limit ? `${fullText.slice(0, limit)}...` : fullText;
  } else {
    shortTextGenerated = shortText;
  }

  const [expanded, setExpanded] = useState(!showButton);
  const props = html
    ? {
        dangerouslySetInnerHTML: {
          __html: expanded ? fullText : shortTextGenerated,
        },
      }
    : {
        children: expanded ? fullText : shortTextGenerated,
      };
  /**
   * Ref to the `TextExpand__text` to detect if its height is more than allowed
   * Used only if `linesAmount` and `lineHeight` are specified
   */
  const textRef = useRef();
  const [lines, setLines] = useState(linesAmount);
  useEffect(() => {
    if (linesAmount && textRef.current) {
      const textHeight = textRef.current.getBoundingClientRect().height;
      if (textHeight > lines * lineHeight) setLines(lines - 1);
    }
  }, []);

  /**
   * Call `onExpanded` callback when user expanded text
   */
  useEffect(() => {
    if (expanded) {
      onExpanded();
    }
  }, [expanded]);

  return (
    <div
      className={classNames(
        "TextExpand",
        theme && `TextExpand--${theme}`,
        allowOriginalFormat && expanded && "TextExpand--origins",
        className,
      )}
    >
      <div
        className="TextExpand__wrapper"
        style={!expanded && lines ? { maxHeight: lines * lineHeight } : {}}
      >
        {expanded ? (
          <div className="TextExpand__text" {...props} />
        ) : (
          <div ref={textRef} className="TextExpand__text" {...props} />
        )}
      </div>
      {showButton && (lines < linesAmount || shortTextGenerated !== fullText) && (
        <button className="TextExpand__link" onClick={() => setExpanded(!expanded)}>
          {expanded
            ? expanderContentOpen || <Trans>Show less</Trans>
            : expanderContent || <Trans>Show more</Trans>}
        </button>
      )}
    </div>
  );
};
